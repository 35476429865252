import SafeService from 'odas-plugins/SafeService';
// @ts-ignore
import algoliaSearch from 'algoliasearch/lite';
import Vue from 'vue';
import {AlgoliaConfig, CriteriaValue, OrganizationSummary} from 'erhgo-api-client';
import type {IndexUiState, UiState} from 'instantsearch.js';

export default class UserIndexSearchService extends SafeService {

  private static PUBLIC_KEY_DURATION_IN_SECONDS = 5 * 60;
  private _uiState: UiState | null = null;

  // @ts-ignore
  // eslint-disable-next-line
  private _searchClient: any = null;
  private _algoliaConfig: AlgoliaConfig | null = null;
  private _organizations: OrganizationSummary[] = [];
  private _criteria: CriteriaValue[] = [];
  private _initialCriteriaFacets = [];

  constructor(private _selectedOrganizationCode: string | undefined) {
    super();
    this.loading = true;
    this.safeCall(async () => {
      await this.fetchAlgoliaConfig();
      await this.fetchDatas();
    });
  }

  async fetchAlgoliaConfig() {
    try {
      this._algoliaConfig = (await Vue.$api.getAlgoliaSearchConfiguration(this._selectedOrganizationCode ?? undefined)).data;
      if (this._searchClient) {
        this._searchClient.transporter.queryParameters['x-algolia-api-key'] = this._algoliaConfig!.apiKey;
      } else {
        this._searchClient = algoliaSearch(
          this._algoliaConfig!.applicationId,
          this._algoliaConfig!.apiKey,
        );
      }
    } finally {
      setTimeout(async () => this.fetchAlgoliaConfig(), (UserIndexSearchService.PUBLIC_KEY_DURATION_IN_SECONDS - 10) * 1000);
    }
  }

  async fetchDatas() {
    this._organizations = (await Vue.$api.getAllRecruiters()).data;
    this._criteria = (await Vue.$api.getCriteria()).data.flatMap(c => c.criteriaValues);
    const facets = await this.searchClient.searchForFacetValues([
      {
        indexName: this.indexName,
        params: {
          facetName: 'criteria',
          facetQuery: '',
          maxFacetHits: 100,
        },
      },
    ]);

    // @ts-ignore
    this._initialCriteriaFacets = facets.flatMap(({facetHits}) => [...facetHits.map(facet => ({
      ...facet,
      label: facet.value,
      value: facet.value,
      isRefined: false,
      count: 0,
    }))]);
  }

  get searchClient() {
    return this._searchClient;
  }

  get indexName() {
    return this._algoliaConfig?.userIndexName;
  }

  get organizations() {
    return this._organizations;
  }

  get criteria() {
    return this._criteria;
  }

  get initialCriteriaFacets() {
    return this._initialCriteriaFacets;
  }

  set uiState(state: UiState) {
    this._uiState = state;
  }

  public computeAlgoliaQuery() {
    const data = this._uiState?.[this.indexName!] as IndexUiState;
    return UserIndexSearchService.computeQuery(data);
  }


  public static computeQuery(data: IndexUiState) {
    const andFacetsAttributes = ['criteria'];
    if (data?.configure) {
      const asKeyValue = (k: string, v: string) => `${k}:"${v}"`;
      const facetAnds = Object.keys(data.refinementList ?? {}) // {a: [b,c], b: [c,d], e:  []}  =>  {a:b AND a:c AND b:c AND b:d}
        .filter(key => andFacetsAttributes.includes(key))
        .filter(key => data.refinementList?.[key]?.length)
        .flatMap(key => data.refinementList![key].map(value => asKeyValue(key, value)));

      const listOfOr = Object.keys(data.refinementList ?? {}) // {a: ['b','c'], b: ['c','d']"}  =>  (a:b OR a:c) AND (b:c OR b:d)
        .filter(key => !andFacetsAttributes.includes(key))
        .filter(key => data.refinementList?.[key]?.length)
        .map(key => `(${data.refinementList?.[key].map(value => asKeyValue(key, value)).join(' OR ')})`)
      ;

      let filtersArray = [...facetAnds, ...listOfOr];
      //@ts-ignore
      if (data.configure?.filters) {
        //@ts-ignore
        filtersArray = [...filtersArray, data.configure.filters];
      }
      const filters = filtersArray.filter(a => !!a).join(' AND ');
      const {aroundLatLng, aroundRadius, query} = data.configure as {
        aroundLatLng: string | undefined,
        aroundRadius: number | undefined,
        query: string | undefined
      };
      return {query, filters, aroundLatLng, aroundRadius};
    } else {
      return null;
    }
  }
}
