import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'odas-plugins';
import Vue from 'vue';
// @ts-ignore
import App from './App.vue';
// @ts-ignore
import i18n from './i18n';
import './plugins/vue-truncate-filter';
import './plugins/vue-wysiwyg';
// @ts-ignore
import vuetify from 'odas-plugins/vuetify';
// @ts-ignore
import router from './router';
import {initKeycloak} from 'odas-plugins/keycloak';
import {baseUrl} from 'odas-plugins/base-url';
// @ts-ignore
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify,
});

const eventBus = new Vue();
Vue.$eventBus = eventBus;
Vue.prototype.$keycloak = eventBus;

const initOptionsBO = {
  realm: process.env.VUE_APP_KEYCLOAK_REALM!,
  url: process.env.VUE_APP_KEYCLOAK_URL || baseUrl('auth'),
  sslRequired: process.env.VUE_APP_KEYCLOAK_SSL_REQUIRED,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID!,
};

initKeycloak(initOptionsBO, 'login-required', () => {
  new Vue({
    // @ts-ignore
    vuetify,
    // @ts-ignore
    router,
    // @ts-ignore
    i18n,
    // eslint-disable-next-line
    render: h => h(App),
  }).$mount('#app');
});
