import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/*eslint-disable */
export default new Vuex.Store({
  state: {
    organizationLoaded: null,
    observedJobActivities: {},
    categories: [],
  },
  mutations: {
    loadOrganization: (state, organization) => {
      state.organizationLoaded = organization;
    },
    unloadOrganization: (state) => {
      state.organizationLoaded = null;
    },
    addObservedJobActivities: (state, activities) => {
      activities.forEach(a => (state.observedJobActivities[a.id] = a));
    },
    setCategories: (state, categories) => {
      state.categories = categories;
    },
  },
  actions: {
    loadOrganization: ({commit}, organization) => {
      commit('loadOrganization', {
        organization,
      });
    },
    unloadOrganization: ({commit}) => {
      commit('unloadOrganization');
    },
  },
  getters: {
    organizationLoaded: (state) => state.organizationLoaded,
  },
});
