<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  methods: {
    addFavicon() {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.type = 'image/png';
      link.href = '/favicons/favicon.ico';
      document.getElementsByTagName('head')[0].appendChild(link);
    },
  },
  created() {
    this.addFavicon();
  },
};
</script>

<style lang="scss">
@import './style/colors.scss';

.v-dialog {
  width: auto;
}

.theme--light.v-data-table thead.v-data-table-header tr th, .v-data-table thead th.column {
  background-color: $mdi-primary;
  color: #000000 !important;
  font-weight: bolder;
}

th i::before {
  color: #ffffff;
}

th.active, th::selection, th i::before, th:hover, th i:hover::before {
  color: $secondary !important;
  font-weight: bolder !important;
}

.v-alert {
  margin-top: 5px;
}

.no-bullet {
  list-style-type: none;
}

.invisible {
  visibility: hidden;
}

a.v-tab {
  text-transform: capitalize;
}

.v-card__text, .v-card__title {
  word-break: normal !important; //see https://github.com/vuetifyjs/vuetify/issues/9130
}

.ql-editor {
  max-height: 300px;
}

.render-eol {
  white-space: pre-wrap;
}

.small-panel .v-expansion-panel-header {
  padding-top: 0;
  padding-bottom: 0;
}

.small-panel .v-expansion-panel-content__wrap {
  padding-top: 0;
  padding-bottom: 0;
}

.v-btn.primary {
  color: black !important;
}

.very-small .v-icon--dense {
  font-size: 15px !important;
}

.very-small .v-label {
  font-size: 13px !important;
}

</style>
